.icon-container {
  animation-name: bounce-in-fwd, icon-spin;
  animation-iteration-count: 1, infinite;
  animation-timing-function: ease, linear;
  animation-duration: 1.1s, 5s;
  animation-delay: 0s, 1.1s;
  -webkit-animation-name: bounce-in-fwd, icon-spin;
  -webkit-iteration-count: 1, infinite;
  -webkit-timing-function: ease, linear;
  -webkit-duration: 1.1s, 5s;
  -webkit-delay: 0s, 1.1s;
  -moz-animation-name: bounce-in-fwd, icon-spin;
  -moz-iteration-count: 1, infinite;
  -moz-timing-function: ease, linear;
  -moz-duration: 1.1s, 5s;
  -moz-delay: 0s, 1.1s;
  margin-left: 2%;
  height: 70%;
  width: auto;
  .icon-connector {
    position: absolute;
    z-index: 2;
    transition: 0.3s ease-in-out;
    height: 103%;
    width: 103%;
  }
  .icon-left {
    position: relative;
    z-index: 1;
    transition: 0.3s ease-in-out;
    margin-right: 3px;
    height: 100%;
    width: auto;
  }

  .icon-right {
    position: relative;
    z-index: 1;
    transition: 0.3s ease-in-out;
    height: 100%;
    width: auto;
  }
  @media (hover: hover) {
    &:hover {
      animation-play-state: paused;
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      .icon-connector {
        animation: icon-connector-spin 0.5s;
        transition: 0.3s ease-in-out;
      }
      .icon-left {
        transition: 0.3s ease-in-out;
        margin-left: -8px;
        padding-right: 8px;
      }
      .icon-right {
        transition: 0.3s ease-in-out;
        margin-right: -8px;
        padding-left: 8px;
      }
    }
  }
  @media (max-width: 768px) {
    &:active {
      animation-play-state: paused;
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      .icon-connector {
        animation: icon-connector-spin 0.5s;
        transition: 0.3s ease-in-out;
      }
      .icon-left {
        transition: 0.3s ease-in-out;
        margin-left: -8px;
        padding-right: 8px;
      }
      .icon-right {
        transition: 0.3s ease-in-out;
        margin-right: -8px;
        padding-left: 8px;
      }
    }
  }
}
