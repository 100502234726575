.header {
  position: fixed;
  z-index: 1;
  top: 0;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 10vw;
  box-shadow: $dark-mode-shadow;
  -webkit-box-shadow: $dark-mode-shadow;
  -moz-box-shadow: $dark-mode-shadow;
  @media (min-width: 768px) {
    height: 76px;
  }
  @media (max-width: 576px) {
    height: 57.6px;
  }
  &.header[theme="light"] {
    background-color: white;
    box-shadow: $light-mode-shadow;
    -webkit-box-shadow: $light-mode-shadow;
    -moz-box-shadow: $light-mode-shadow;
    .header-name,
    .header-separator,
    .header-title {
      color: black;
    }
  }
}

.header-content {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.header-text-container {
  animation-name: tracking-in-expand;
  animation-duration: 0.7s;
}

.header-name {
  color: $opaque-white;
  font-size: 3vw;
  font-weight: 600;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (max-width: 576px) {
    font-size: 17.28px;
  }
  @media (max-width: 367px) {
    font-size: 4.5vw;
  }
}

.header-separator {
  color: $opaque-white;
  font-size: 3vw;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 400;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (max-width: 576px) {
    font-size: 17.28px;
  }
  @media (max-width: 367px) {
    font-size: 4.5vw;
  }
}

.header-title {
  color: $opaque-white;
  font-size: 3vw;
  font-style: italic;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (max-width: 576px) {
    font-size: 17.28px;
  }
  @media (max-width: 367px) {
    font-size: 4.5vw;
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes icon-spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes icon-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes icon-connector-spin {
  0% {
    transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes bounce-in-fwd {
  0% {
    transform: scale(0);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    transform: scale(1);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    transform: scale(0.7);
    animation-timing-function: ease-in;
  }
  72% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  81% {
    transform: scale(0.84);
    animation-timing-function: ease-in;
  }
  89% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
  95% {
    transform: scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
