$feed-spacing: 5vw;
$feed-spacing-mobile: 10vw;
$card-alignment: 1.5vw;
$card-alignment-mobile: 4vw;
$card-border: 2px;
$card-width: 40vw;
$card-width-mobile: 80vw;

.projects-feed-container {
  width: min-content;
  height: 100%;
  display: grid;
  margin-left: $feed-spacing;
  grid-template-columns: min-content min-content;
  grid-template-rows: auto;
  @media (max-width: 768px) {
    margin-left: 0px;
    grid-template-columns: min-content;
    grid-template-rows: auto;
  }
}

.project-card-container {
  display: flex;
  padding-top: calc(#{$feed-spacing} + #{$card-alignment});
  &:last-child {
    padding-bottom: $feed-spacing;
  }
  &:nth-child(even) {
    padding-right: $card-alignment;
    padding-left: $feed-spacing;
  }
  &:nth-child(odd) {
    padding-right: $card-alignment;
  }
  &[theme="light"] {
    .card-contents-container {
      background-color: white;
    }
    .card-shadow {
      box-shadow: $light-mode-shadow;
    }
  }
  @media (max-width: 768px) {
    padding-top: calc(#{$feed-spacing-mobile} + #{$card-alignment-mobile});
    &:nth-last-child(-n + 2) {
      padding-bottom: 0px;
    }
    &:nth-child(even) {
      padding-right: $card-alignment-mobile;
      padding-left: 0px;
    }
    &:nth-child(odd) {
      padding-right: 0px;
    }
    &:last-child {
      padding-bottom: $feed-spacing-mobile * 6;
    }
  }
}

.card-header {
  position: absolute;
  z-index: 3;
  margin-left: $card-alignment;
  margin-top: calc(-#{$card-alignment} - #{$card-border});
  background-color: $pastel-red;
  border: solid $card-border $light-gray;
  width: $card-width;
  max-width: 620px;
  height: $card-alignment;
  max-height: 347px;
  @media (max-width: 768px) {
    width: $card-width-mobile;
    height: $card-alignment-mobile;
    margin-left: $card-alignment-mobile;
    margin-top: calc(-#{$card-alignment-mobile} - #{$card-border});
  }
}

.card-shadow {
  position: absolute;
  z-index: 1;
  background-color: $pastel-blue;
  box-shadow: $dark-mode-shadow;
  border: solid $card-border $light-gray;
  width: $card-width;
  max-width: 620px;
  height: calc(#{$card-width} * 0.56);
  max-height: 347px;
  @media (max-width: 768px) {
    width: $card-width-mobile;
    height: $card-width-mobile;
    max-height: none;
  }
}

.card-contents-container {
  position: relative;
  z-index: 2;
  left: $card-alignment;
  bottom: $card-alignment;
  background-color: $highlight-gray;
  border: solid $card-border $light-gray;
  width: $card-width;
  max-width: 620px;
  height: calc(#{$card-width} * 0.56);
  max-height: 347px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: $card-width-mobile;
    height: $card-width-mobile;
    left: $card-alignment-mobile;
    bottom: $card-alignment-mobile;
    max-height: none;
    &:active {
      .project-name {
        font-size: 8.2vw;
        text-decoration: underline solid $pastel-blue;
      }
      .more-info-button {
        font-size: 2.7vw;
      }
    }
  }
  @media (hover: hover) {
    cursor: pointer;
    &:hover {
      .project-name {
        font-size: 2.1vw;
        border-bottom: 2px solid $pastel-blue;
      }
      .more-info-button {
        font-size: 14px;
      }
    }
  }
}

.project-name {
  font-style: italic;
  font-size: 2vw;
  color: $dark-gray;
  @media (max-width: 768px) {
    text-align: center;
    width: max-content;
    max-width: 75%;
    height: min-content;
    font-size: 8vw;
  }
}

.right-info-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: $card-alignment;
  top: calc(#{$card-alignment} * 2);
  @media (max-width: 768px) {
    right: $card-alignment-mobile;
    top: calc(#{$card-alignment-mobile} * 2);
  }
}

.project-type {
  margin: 0;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 700;
  color: $highlight-gray;
  border-radius: 15px;
  padding: 5px 8px;
  background-color: $dark-gray;
  @media (max-width: 651px) {
    font-size: 1.9vw;
  }
}

.more-info-button {
  margin: 0;
  width: max-content;
  font-size: 13px;
  font-weight: 500;
  color: $light-gray;
  border-radius: 15px;
  padding: 5px 8px;
  background-color: $opaque-white-light;
  @media (max-width: 651px) {
    font-size: 2.5vw;
  }
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      transform: scale(1.03);
    }
  }
  @media (max-width: 768px) {
    &:active {
      transform: scale(1.03);
    }
  }
  &[theme="light"] {
    background-color: $opaque-lighter-gray;
  }
}

.project-stack-container {
  position: absolute;
  left: $card-alignment;
  top: calc(#{$card-alignment} * 2);
  @media (max-width: 768px) {
    left: $card-alignment-mobile;
    top: calc(#{$card-alignment-mobile} * 2);
  }
}

.stack-item {
  margin: 0;
  margin-bottom: 8px;
  width: max-content;
  font-size: 13px;
  font-weight: 700;
  color: $opaque-white;
  border-radius: 15px;
  padding: 5px 8px;
  &[type="React Native"],
  &[type="React"] {
    background-color: $pastel-blue;
  }
  &[type="Ruby on Rails"],
  &[type="Jekyll"] {
    background-color: $pastel-red;
  }
  &[type="Javascript"] {
    color: $opaque-light-gray;
    background-color: #f0db4f;
  }
  &[type='WordPress'] {
    background-color: #316192;
  }
}

.project-links-container {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 0;
}

.link-item {
  flex-grow: 1;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  color: $highlight-gray;
  background-color: $light-gray;
  padding: 5px 0px;
  &:hover {
    color: white;
  }
  @media (max-width: 768px) {
    margin-top: 1px;
    min-width: 50%;
  }
}

.more-info-modal {
  position: absolute;
  z-index: 3;
  background-color: $highlight-gray;
  border: solid $card-border $light-gray;
  width: $card-width;
  max-width: 620px;
  height: calc(#{$card-width} * 0.56);
  max-height: 347px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: $card-width-mobile;
    height: $card-width-mobile;
    max-height: none;
  }
  &[theme="light"] {
    background-color: white;
  }
}

.close-button {
  position: absolute;
  top: calc(#{$card-alignment * 2});
  right: $card-alignment;
  margin: 0;
  width: max-content;
  font-size: 13px;
  font-weight: 500;
  color: $light-gray;
  border-radius: 15px;
  padding: 5px 8px;
  background-color: $opaque-white-light;
  @media (max-width: 768px) {
    right: $card-alignment-mobile;
    top: calc(#{$card-alignment-mobile} * 2);
  }
  @media (max-width: 651px) {
    font-size: 1.9vw;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  &[theme="light"] {
    background-color: $opaque-lighter-gray;
  }
}

.project-description {
  margin: 0;
  text-align: left;
  font-style: italic;
  font-size: 1.2vw;
  color: $dark-gray;
  position: absolute;
  left: $card-alignment;
  width: 75%;
  top: calc(#{$card-alignment} * 2);
  @media (max-width: 768px) {
    top: calc(#{$card-alignment-mobile} * 2);
    left: $card-alignment-mobile;
    font-size: 4vw;
  }
}

.tags-container {
  position: absolute;
  width: calc(100% - #{$card-alignment * 2});
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  max-height: 45%;
  justify-content: space-between;
  padding-bottom: $card-alignment;
  bottom: 0;
  @media (max-width: 768px) {
    width: calc(100% - #{$card-alignment-mobile * 2});
    padding-bottom: $card-alignment-mobile;
  }
}

.tag-item {
  margin: 3px 3px;
  width: max-content;
  font-size: 10px;
  font-weight: 700;
  color: $opaque-white;
  background-color: $opaque-white-light;
  border-radius: 15px;
  padding: 5px 8px;
  @media (max-width: 768px) {
    font-size: 8px;
  }
  &[theme="light"] {
    background-color: $opaque-light-gray;
  }
  &[type="React Native"],
  &[type="React Native Swipeable"],
  &[type="React"],
  &[type="React Hooks"],
  &[type="React Navigation"],
  &[type="React Native Double Tap"],
  &[type="React Native Grid List"],
  &[type="React Native Debugger"] {
    background-color: $pastel-blue;
  }
  &[type="Ruby on Rails"],
  &[type="bcrypt"],
  &[type="Active Model Serializers"],
  &[type="Active Storage"] {
    background-color: $pastel-red;
  }
  &[type="Javascript"] {
    color: $opaque-light-gray;
    background-color: #f0db4f;
  }
  &[type="Heroku"] {
    background-color: #400099;
  }
  &[type="Expo"],
  &[type="Expo Images Picker"],
  &[type="Netlify"] {
    background-color: black;
  }
  &[type="React-Redux"] {
    background-color: #7f42c3;
  }
  &[type="PostgreSQL"],
  &[type="BuilderX"],
  &[type="Adobe Photoshop (Wireframes)"],
  &[type='WordPress'] {
    background-color: #316192;
  }
  &[type="SCSS"] {
    background-color: #cd669a;
  }
  &[type="HTML5"] {
    background-color: #e44d26;
  }
  &[type="CSS3"] {
    background-color: #264de4;
  }
}
