// Variables

// Colors
// $base-color: #c6538c;
// $border-dark: rgba($base-color, 0.88);

// Colors
$darker-gray: #131315;
$dark-gray: #202124;
$light-gray: #2c2d31;
$lighter-gray: #46484e;
$highlight-gray: #b0b1b7;
$icon-gradient: linear-gradient(
  190deg,
  rgba(32, 33, 36, 1) 0%,
  rgba(70, 72, 78, 1) 100%
);
$toggle-gradient: linear-gradient(
  190deg,
  rgba(70, 72, 78, 1) 0%,
  rgba(32, 33, 36, 1) 100%
);
$opaque-white: rgba(white, 0.75);
$opaque-white-light: rgba(white, 0.25);
$opaque-light-gray: rgba($light-gray, 0.75);
$opaque-lighter-gray: rgba($lighter-gray, 0.25);
$pastel-red: #e06060;
$pastel-red-light: #ffa2a2;
$pastel-blue: #50cddc;

//Box Shadow
$light-mode-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.14);
$dark-mode-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.45);
$icon-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

// Variables
$bg-dark: $dark-gray;
$bg-light: $pastel-red-light;

// Typography
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$font-family-base: "Montserrat", sans-serif;

// Animations
$tracking-in-expand: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
  both;
$fade-in: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
$slide-in-right: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
$pulsate-back: pulsate-bck 0.5s ease-in-out both;

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

// Functions
// Mixins
// Helpers
