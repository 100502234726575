.bio-container {
  position: relative;
  z-index: 3;
  margin-left: 6%;
  margin-right: 6%;
  max-width: 445px;
  @media (max-width: 768px) {
    margin-top: 6%;
  }
  & p {
    animation: $fade-in;
    -webkit-animation: $fade-in;
  }
  &.bio-container[theme="light"] {
    color: $dark-gray;
  }
}

.signature-container {
  width: 40%;
  min-width: 135px;
}

.signature {
  max-width: 100%;
}

.intro,
.intro-2 {
  margin: 0;
}

.background-images-container {
  z-index: 2;
  position: absolute;
  width: 50vw;
  height: 50vw;
  max-width: 700px;
  right: 8%;
  bottom: 10%;
  @media (max-width: 768px) {
    position: relative;
    width: 80vw;
    height: 70vw;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    bottom: 0;
  }
  @media (min-width: 1440px) {
    right: 15%;
  }
}

.background-image {
  position: absolute;
  left: 17.5px;
  bottom: 0;
  max-width: 100%;
  height: auto;
  z-index: 2;
}

.background-shadow {
  position: absolute;
  max-width: 100%;
  height: auto;
  bottom: -15px;
  left: -17.5px;
  z-index: 1;
}

.quick-facts-container {
  display: flex;
  justify-content: center;
  position: absolute;
  border-left: solid $opaque-white 4px;
  background-color: $light-gray;
  right: 0;
  z-index: 1;
  width: max-content;
  padding-left: 4vw;
  padding-right: 4vw;
  height: 100%;
  @media (min-width: 1440px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  @media (max-width: 768px) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    // height: min-content;
  }
  &[theme="light"] {
    color: black;
    background-color: $opaque-white;
    border-color: $pastel-blue;
    .location {
      border-color: black;
    }
  }
}

.quick-facts-container[theme="light"] {
  &.favorites :first-child {
    color: $pastel-blue;
  }
}

.quick-facts-content {
  margin-top: 5vw;
  & p {
    width: max-content;
    height: max-content;
    animation: $fade-in;
    -webkit-animation: $fade-in;
    font-size: 20px;
    font-weight: 600;
    line-height: 17px;
    margin: 0;
    &.location {
      padding: 10px;
      margin-bottom: 2vw;
      border: solid 1px white;
      font-weight: 500;
      font-size: 15px;
    }
  }
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  & span.city {
    font-weight: 700;
  }
}

.favorites {
  & :first-child {
    -webkit-animation: $tracking-in-expand;
    animation: $tracking-in-expand;
    color: $pastel-red;
    font-style: italic;
    font-weight: 900;
  }
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.hobbies {
  margin-top: 2vw;
  & :first-child {
    -webkit-animation: $tracking-in-expand;
    animation: $tracking-in-expand;
    color: $pastel-blue;
    font-style: italic;
    font-weight: 900;
  }
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 60vw;
  }
}

// .quick-fact-item {
//   @media (hover: hover) {
//     &:hover {
//       color: aquamarine;
//     }
//   }
// }
