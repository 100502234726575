.resume-document {
  width: 100%;
  height: 100%;
}

.resume-download-link {
  background-color: $light-gray;
  border-radius: 4px;
  margin-top: 15vh;
  text-decoration: none;
  color: $opaque-white;
  padding: 3vw;
  border: solid 2px $opaque-white;
  font-weight: 600;
  font-size: 6vw;
  &[theme="light"] {
    color: white;
    border-color: white;
    background-color: $light-gray;
  }
  &:active {
    border-color: $pastel-blue;
  }
}
