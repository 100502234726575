.footer {
  position: fixed;
  background-color: $light-gray;
  display: flex;
  z-index: 1;
  align-items: center;
  width: 100vw;
  height: 15vw;
  bottom: 0;
  box-shadow: $dark-mode-shadow;
  -webkit-box-shadow: $dark-mode-shadow;
  -moz-box-shadow: $dark-mode-shadow;
  &[theme="light"] {
    background-color: white;
    box-shadow: $light-mode-shadow;
    -webkit-box-shadow: $light-mode-shadow;
    -moz-box-shadow: $light-mode-shadow;
  }
  @media (min-width: 768px) {
    background-color: $dark-gray;
    &[theme="light"] {
      background-color: $pastel-red-light;
    }
  }
  @media (min-width: 768px) {
    height: 76px;
  }
}

.menu-button-container {
  position: relative;
  z-index: 3;
  margin-left: 2%;
  height: 60%;
  width: auto;
}

.menu-button {
  opacity: 0.6;
  position: relative;
  height: 100%;
  width: auto;
  @media (min-width: 576px) {
    &:hover {
      opacity: 0.2;
    }
  }
  &:hover {
    opacity: 0.2;
  }
  &.clicked {
    animation: pulsate-bck 0.5s ease-in-out both;
  }
}

.light-dark-container {
  position: relative;
  z-index: 3;
  margin-left: 2%;
  height: 2vw;
  width: 2vw;
  box-shadow: $dark-mode-shadow;
  border-style: solid;
  border-color: $opaque-white;
  border-width: 2px;
  border-radius: 20px;
}

.dark {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background-color: $dark-gray;
}

.mobile-menu {
  position: absolute;
  transform: translateY(100%);
  display: grid;
  grid-auto-flow: row;
  row-gap: 2px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: min-content;
  background-color: $dark-gray;
  bottom: 0;
  box-shadow: $dark-mode-shadow;
  -webkit-box-shadow: $dark-mode-shadow;
  -moz-box-shadow: $dark-mode-shadow;
  &.mobile-menu[theme="light"] {
    background-color: $pastel-red-light;
    box-shadow: $light-mode-shadow;
    -webkit-box-shadow: $light-mode-shadow;
    -moz-box-shadow: $light-mode-shadow;
  }
  &.mobile-menu[theme="light"] .menu-item {
    background-color: white;
  }
  &.mobile-menu[theme="light"] .menu-item-name {
    color: black;
  }
  &.entering {
    animation: slide-in-bottom 0.5s both;
  }
  &.exiting {
    animation: slide-out-bottom 1.5s both;
    &.icon-container {
      visibility: hidden;
    }
  }
}

.desktop-menu {
  position: absolute;
  display: grid;
  height: 76px;
  background-color: $light-gray;
  grid-auto-flow: column;
  column-gap: 4vw;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: min-content;
  bottom: 0;
  &.desktop-menu[theme="light"] {
    background-color: white;
  }
}

.menu-item {
  background-color: $light-gray;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 15vw;
  width: 100vw;
  @media (min-width: 768px) {
    height: 76px;
    width: min-content;
  }
  &[theme="light"] {
    background-color: white;
    & .menu-item-name {
      color: black;
    }
  }
  @media (max-width: 768px) {
    &:active {
      background-color: $opaque-light-gray;
      &:active[theme="light"] {
        background-color: $opaque-white;
      }
    }
  }
}

.menu-item-link {
  text-decoration: none;
}

.menu-item-name {
  position: relative;
  height: min-content;
  width: min-content;
  color: $opaque-white;
  font-size: 4.5vw;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  transition: border 0.5s ease-in-out;
  @media (min-width: 768px) {
    font-size: 23px;
  }
  @media (max-width: 367px) {
    font-size: 4.5vw;
  }
  &:hover {
    color: white;
  }
  &.selected {
    border-bottom: 2px solid $pastel-blue;
    transition: border 0.5s ease-in-out;
    -webkit-animation: menu-item-selected 0.2s ease-in-out both;
    animation: menu-item-selected 0.2s ease-in-out both;
  }
  &.deselected {
    -webkit-animation: menu-item-deselected 0.2s ease-in-out both;
    animation: menu-item-deselected 0.2s ease-in-out both;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(80%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes slide-in-bottom-mobile {
  0% {
    transform: translateY(80%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-out-bottom-mobile {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(80%);
  }
}

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes menu-item-selected {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes menu-item-selected {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@-webkit-keyframes menu-item-deselected {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes menu-item-deselected {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
