.splash-screen {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: $bg-dark;
  animation: fade-out-bck 1.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s both;
}

.splash-icon-container {
  position: absolute;
  animation-name: bounce-in-fwd, icon-spin;
  animation-iteration-count: 1, infinite;
  animation-timing-function: ease, linear;
  animation-duration: 1.1s, 5s;
  animation-delay: 0s, 1.1s;
  -webkit-animation-name: bounce-in-fwd, icon-spin;
  -webkit-iteration-count: 1, infinite;
  -webkit-timing-function: ease, linear;
  -webkit-duration: 1.1s, 5s;
  -webkit-delay: 0s, 1.1s;
  -moz-animation-name: bounce-in-fwd, icon-spin;
  -moz-iteration-count: 1, infinite;
  -moz-timing-function: ease, linear;
  -moz-duration: 1.1s, 5s;
  -moz-delay: 0s, 1.1s;
  height: 10vh;
  width: auto;
  .splash-icon-connector {
    position: absolute;
    z-index: 2;
    animation: icon-connector-spin 0.5s;
    transition: 0.3s ease-in-out;
    height: 103%;
    width: 103%;
  }
  .splash-icon-left {
    position: relative;
    z-index: 1;
    transition: 0.3s ease-in-out;
    margin-right: 5px;
    height: 100%;
    width: auto;
  }

  .splash-icon-right {
    position: relative;
    z-index: 1;
    transition: 0.3s ease-in-out;
    height: 100%;
    width: auto;
  }
}

@keyframes fade-out-bck {
  0% {
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    transform: translateZ(-80px);
    opacity: 0;
  }
}
