@import "./projects-page";
@import "./about-page";
@import "./resume-page";
@import "./blog-page";

.page-container {
  position: fixed;
  top: 10vw;
  bottom: 15vw;
  height: calc(100vh - 25vw);
  width: 100vw;
  overflow: scroll;
  @media (max-width: 576px) {
    height: calc(100vh - 15vw - 57.6px);
    top: 57.6px;
    bottom: 15vw;
  }
  @media (min-width: 576px) {
    height: calc(100vh - 25vw);
    top: 10vw;
    bottom: 15vw;
  }
  @media (min-width: 768px) {
    height: calc(100vh - 152px);
    top: 76px;
    bottom: 76px;
  }
  &.about {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      display: block;
    }
  }
  &.projects {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  &.blog {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  &.resume {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}
