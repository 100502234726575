@import "./utils/utils";
@import "./components/_components-index";
@import "./pages/pages-index";

.App {
  display: flex;
  height: 100vh;
  width: 100vw;
  color: $opaque-white;
  background-color: $bg-dark;
  font-family: $font-family-base;
  &.App[theme="light"] {
    background-color: $pastel-red-light;
  }
}
